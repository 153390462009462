import { PARTNERS_TO_REMOVE_GTM } from './constants';

export const GTMPageView = (url: string) => {
  interface PageEventProps {
    event: string;
    page: string;
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};

export const clickEvent = (event: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.dataLayer?.push({
    event: event,
  });
};

export const clickEventWithVariable = (
  event: string,
  variableValue: string,
  variableName: string,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.dataLayer?.push({
    event: event,
    [variableName]: variableValue,
  });
};

export const clickEventWithObject = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.dataLayer?.push(data);
};

export const clickBtnSelecaoCobertura = (cobertura: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  dataLayer.push({
    event: 'clique_btn_selecao_cobertura',
    pageView: 'cadastro_tela_1',
    cobertura: cobertura,
  });
};

export const gtmStepChange = (step: number) => {
  if (step === 2) {
    const userCombo = JSON.parse(
      localStorage.getItem('userCombo') as string,
    )
      .addons.map(function (o: any) {
        return o.name;
      })
      .join(', ');
    clickBtnSelecaoAssistencia(userCombo, 'cadastro_tela_2');
  } else if (step === 3) {
    const userCombo = JSON.parse(
      localStorage.getItem('userCombo') as string,
    )
      .addons.map(function (o: any) {
        return o.name;
      })
      .join(', ');
    clickBtnSelecaoAssistencia(userCombo, 'cadastro_tela_3');
  } else if (step === 5) {
    const userCombo = JSON.parse(
      localStorage.getItem('userCombo') as string,
    );
    const value = userCombo.value;
    const productName = userCombo.product_name;
    const assistance = userCombo.addons
      .map(function (o: any) {
        return o.name;
      })
      .join(', ');
    clickBtnContratar(value, productName, assistance);
  }
};

export const clickBtnContratar = (
  value: number,
  product: string,
  assistance: string,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  dataLayer.push({
    event: 'clique_btn_contratar',
    pageView: 'cadastro_tela_5',
    produto: {
      cobertura: product,
      assistencias: assistance,
      valor_final: value,
    },
  });
};
export const clickBtnSelecaoAssistencia = (
  assistencia: string,
  pageView: string,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  dataLayer.push({
    event: 'clique_btn_selecao_assistencia',
    pageView: pageView,
    assistencia_adicional: assistencia,
  });
};

// THIS SHOULD BE REMOVED
export const getPartnerToRemove = (partnerId: string) => {
  return PARTNERS_TO_REMOVE_GTM.find(
    (p) => p.id === partnerId || window.location.href.includes(p.name),
  );
};

export const shouldRemoveGTM = (partnerId: string) => {
  const partner = getPartnerToRemove(partnerId);
  if (!partner) {
    return false;
  }
  const isPartnerDomain = partner.domain === window.location.host;
  const isPartnerInUrl = window.location.href.includes(partner.name);
  const isPartnerWhitelabel = window.location.href.includes(partner.id);
  return isPartnerDomain || isPartnerInUrl || isPartnerWhitelabel;
};
