import { BillingStatus } from '../components/ClientArea/BillingDetails/BillingDetails';

export const FLIX_PRIMARY_COLOR = '#612FE9';
export const FLIX_SECONDARY_COLOR = '#12BDE5';
export const CLAIMS_LIST = [
  'Dano Elétrico',
  'Raio, Incendio e/ou Explosão',
  'Roubo ou Furto',
];

export const PAYMENT_STATUS: BillingStatus = {
  pending: {
    color: 'alert',
    text: 'Em atraso',
  },
  paid: {
    color: 'success',
    text: 'Paga',
  },
  review: {
    color: 'blue',
    text: 'Em revisão',
  },
  canceled: {
    color: 'error',
    text: 'Cancelada',
  },
  scheduled: {
    color: 'blue',
    text: 'Agendado',
  },
};

// this should be removed asap
type Partner = {
  name: string;
  id: string;
  domain: string;
};
export const PARTNERS_TO_REMOVE_GTM: Partner[] = [
  {
    name: 'ewally',
    id: 'd63ffdad-a7f2-4ab7-9635-1f3c8064bdd1',
    domain: 'ewally.com.br',
  },
];

export const VOUCHER_VALIDATION_TIME = 24 * 60 * 60 * 1000;

export const ROUTE_NAME: { [key: string]: string } = {
  '/aderir/1': `Checkout - passo 1`,
  '/aderir/2': `Checkout - passo 2`,
  '/aderir/3': `Checkout - passo 3`,
  '/aderir/4': `Checkout - passo 4`,
  '/aderir/5': `Checkout - passo 5`,
  '/concluido': `Concluido`,
  '/': `Home`,
};

export const ADDON_IMG: { [key: string]: string } = {
  Eletroassist: '/eletroassist_checkout.svg',
  Emergency: '/emergencial_checkout.svg',
  'Help Desk': '/helpdesk_checkout.svg',
  Pet: '/pet_checkout.svg',
};

export const ADDON_NAME: { [key: string]: string } = {
  Emergency: 'Emergencial',
  Eletroassist: 'Eletroassist',
};
