/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Box, Text, Flex, Image, Link } from 'theme-ui';
import { useRouter } from 'next/router';
import { clickEvent } from '../../../utils/gtm';

type FooterListItemType = {
  href: string;
  text: string;
  onclick: string;
};

export default function Footer() {
  const router = useRouter();
  const FooterListItem = (props: FooterListItemType) => {
    const { href, text, onclick } = props;
    return (
      <Link
        href={href}
        onClick={() => clickEvent(onclick)}
        target="blank"
        mr="4"
        sx={{
          textDecoration: 'none',
          fontWeight: 'normal',
          fontSize: '16px',
          color: '#FFF',
          '&:hover': {
            color: router.pathname.includes('2')
              ? 'primary2business'
              : 'secondary',
          },
          '@media screen and (max-width: 1130px)': { marginRight: '0' },
        }}>
        {text}
      </Link>
    );
  };

  return (
    <Box
      as="footer"
      bg="black"
      sx={{
        color: '#FFF',
        padding: '4rem 6rem 1rem 6rem',
        '@media screen and (max-width: 1280px)': {
          padding: '4rem 2rem 1rem 2rem',
        },
      }}>
      <Image
        sx={{ margin: '0 auto' }}
        src={
          router.pathname.includes('2')
            ? './icon-logo.svg'
            : './footer_logo.svg'
        }
      />
      <Flex
        mt="4"
        sx={{ justifyContent: 'center', justifySelf: 'center' }}>
        <Link
          href="https://www.linkedin.com/company/flixcomvc"
          onClick={() => clickEvent('clique_btn_redesocial_linkedin')}
          target="blank">
          <Image
            sx={{ '&:hover': { cursor: 'pointer', fill: 'secondary' } }}
            mr="4"
            src="./linkedin_icon.svg"
            alt="Linkedin"
            title="Siga-nos no Linkedin!"
          />
        </Link>
        <Link
          href="https://www.instagram.com/flix.vc/"
          onClick={() => clickEvent('clique_btn_redesocial_instagram')}
          target="_blank">
          <Image
            sx={{ '&:hover': { cursor: 'pointer', fill: 'secondary' } }}
            mr="4"
            src="./instagram_icon.svg"
            alt="Instagram"
            title="Siga-nos no Instagram!"
          />
        </Link>
        <Link
          href="https://www.facebook.com/flix.vc"
          onClick={() => clickEvent('clique_btn_redesocial_facebook')}
          target="_blank">
          <Image
            sx={{ '&:hover': { cursor: 'pointer', fill: 'secondary' } }}
            src="./facebook_icon.svg"
            alt="Facebook"
            title="Siga-nos no Facebook!"
          />
        </Link>
      </Flex>
      <Link
        sx={{
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
            textDecorationColor: 'white',
          },
        }}
        target="_blank"
        href={
          router.pathname.includes('2')
            ? 'mailto:flix2business@flix.com.vc'
            : 'mailto:contato@flix.com.vc'
        }>
        <Text
          as="p"
          mt="3"
          mb="1rem"
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            color: 'white',
          }}>
          {router.pathname.includes('2') ? (
            <Text>flix2business@flix.com.vc</Text>
          ) : (
            <Text>contato@flix.com.vc</Text>
          )}
        </Text>
      </Link>

      {/*<Box>*/}
      {/*  <Text*/}
      {/*    as="p"*/}
      {/*    sx={{ textAlign: 'center', fontSize: '16px', margin: '0 auto' }}>*/}
      {/*    Precisa contratar para o seu escritório?*/}
      {/*    <Link*/}
      {/*      ml="5px"*/}
      {/*      href={*/}
      {/*        router.pathname.includes('2')*/}
      {/*          ? 'mailto:flix2business@flix.com.vc'*/}
      {/*          : 'mailto:contato@flix.com.vc'*/}
      {/*      }*/}
      {/*      onClick={() => {*/}
      {/*        clickEvent('clique_footer_fale_conosco');*/}
      {/*      }}*/}
      {/*      target="blank"*/}
      {/*      sx={{*/}
      {/*        fontWeight: 'bold',*/}
      {/*        color: router.pathname.includes('2')*/}
      {/*          ? 'primary2business'*/}
      {/*          : 'secondary',*/}
      {/*        textDecoration: 'none',*/}
      {/*        ':hover': { textDecoration: 'underline' },*/}
      {/*      }}>*/}
      {/*      Fale conosco*/}
      {/*    </Link>*/}
      {/*  </Text>*/}
      {/*</Box>*/}

      <Box
        mt="4"
        as="ul"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '@media screen and (max-width: 1130px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}>
        {router.pathname.includes('/2business') ? (
          <FooterListItem
            href="/"
            text="Flix"
            onclick="clique_footer_home"
          />
        ) : (
          <FooterListItem
            href="/2business"
            text="Flix2Business"
            onclick="clique_footer_flix2business"
          />
        )}
        <FooterListItem
          href={`${
            !router.pathname.includes('/2business')
              ? '/area-cliente'
              : 'https://2business.flix.com.vc'
          }`}
          text="Área do Cliente"
          onclick="clique_footer_area_cliente"
        />
        <FooterListItem
          href="/politicas-de-privacidade"
          text="Política de Privacidade"
          onclick="clique_footer_politica_privacidade"
        />
      </Box>
      <Box
        mt="4"
        mb="4"
        as="ul"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '@media screen and (max-width: 1130px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}>
        <Link
          target="blank"
          onClick={() => {
            clickEvent('clique_footer_condicoes_gerais_80k');
          }}
          sx={{
            borderRadius: '4px',
            marginRight: '10px',
            padding: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            textDecoration: 'none',
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#FFF',
            backgroundColor: '#4C5461',
            textAlign: 'center',
            '&:hover': {
              color: router.pathname.includes('2')
                ? 'primary2business'
                : 'secondary',
            },
            '@media screen and (max-width: 1130px)': { marginRight: '0' },
            '@media screen and (max-width: 1280px)': {
              marginBottom: '10px',
            },
          }}
          href={`${process.env.URL_BUCKET}Condicoes_Gerais_Seguro_Compreensivo_Residencial_FBXG_Seguros_Final.pdf`}>
          Condições Gerais das Coberturas
        </Link>
        <Link
          target="blank"
          sx={{
            backgroundColor: '#4C5461',
            borderRadius: '4px',
            marginRight: '10px',
            padding: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            textDecoration: 'none',
            fontWeight: 'normal',
            fontSize: '16px',
            display: 'flex',
            color: '#FFF',
            '&:hover': {
              color: router.pathname.includes('2')
                ? 'primary2business'
                : 'secondary',
            },
            '@media screen and (max-width: 1130px)': {
              marginRight: '0',
              minWidth: '253px',
              justifyContent: 'center',
            },
          }}
          onClick={() => {
            clickEvent('clique_footer_manual_assistencias');
          }}
          href={`${process.env.URL_BUCKET}Condicoes_gerais_Assistencia_Flix.pdf`}>
          <div
            style={{
              placeSelf: 'center',
            }}>
            Manual das Assistências
          </div>
        </Link>
      </Box>
      <Text
        as="p"
        mt="3"
        mb="1rem"
        sx={{
          textAlign: 'center',
          fontSize: '12px',
          color: 'white',
        }}>
        O seguros oferecidos pela Flix são garantidos pela KOVR
      </Text>
      <Text
        as="p"
        mt="3"
        mb="1rem"
        sx={{
          textAlign: 'center',
          fontSize: '12px',
          color: 'white',
        }}>
        Razão Social: KOVR SEGURADORA S.A. CNPJ: 42.366.302/0001-28
      </Text>
      <Text
        as="p"
        mt="3"
        mb="1rem"
        sx={{
          textAlign: 'center',
          fontSize: '12px',
          color: 'white',
        }}>
        Processo SUSEP: 15414.606982/2021-96
      </Text>
    </Box>
  );
}
